<template>
  <a-card bordered="false">
    <a-form :form="form">
      <a-form-item label="类目名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="['category_name', {rules: [{required: true, message: '请输入'}]}]" />
      </a-form-item>
      <a-form-item label="关键字" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="['keywords', {rules: [{required: true, message: '请输入'}]}]" />
      </a-form-item>
      <a-form-item label="父级别" :labelCol="labelCol" :wrapperCol="wrapperCol" v-if="record.level===2">
        <a-input v-decorator="['parent_name', {rules: [{required: false, message: '请输入'}]}]" disabled />
      </a-form-item>
      <a-form-item label="级别" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-select
          disabled
          style="width: 140px"
          placeholder="选择"
          v-decorator="['level', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
        >
          <a-select-option :value="1">
            一级类目
          </a-select-option>
          <a-select-option :value="2">
            二级类目
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="类目图标" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <div class="clearfix" style="display: inline-block">
          <a-form-item label="图片" v-show="false">
            <a-textarea v-decorator="['icon_url', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
          <a-upload
            v-decorator="['icon_url2',]"
            name="file"
            accept="image/png, image/jpeg, image/jpg"
            :action="actionUrl"
            list-type="picture-card"
            :file-list="fileList"
            :data="new_multipart_params"
            :before-upload="beforeUpload"
            @preview="handlePreview"
            @change="handleChange"
          >
            <div v-if="fileList.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">
                修改图片上传
              </div>
            </div>
          </a-upload>
          <span>提示：类目图标宽高比为1:1</span>
          <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </div>
      </a-form-item>
      <a-form-item label="类目图片" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <div class="clearfix" style="display: inline-block">
          <a-form-item label="图片" v-show="false">
            <a-textarea v-decorator="['picture_url', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
          <a-upload
            v-decorator="['picture_url2',]"
            name="file"
            accept="image/png, image/jpeg, image/jpg"
            :action="actionUrl"
            list-type="picture-card"
            :file-list="fileList2"
            :data="new_multipart_params2"
            :before-upload="beforeUpload2"
            @preview="handlePreview2"
            @change="handleChange2"
          >
            <div v-if="fileList2.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">
                修改图片上传
              </div>
            </div>
          </a-upload>
          <span>提示：类目图片宽高比为 3:1</span>
          <a-modal :visible="previewVisible2" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage2" />
          </a-modal>
        </div>
      </a-form-item>
      <a-form-item label="类目简介" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input
          v-decorator="['desc', {rules: [{ required: true, message: '请输入' }]}]" />
      </a-form-item>
      <a-form-item label="排序序号" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input
          v-decorator="['sort_order', {rules: [{ required: true, message: '请输入' }]}]" />
      </a-form-item>
      <a-form-item
        v-bind="buttonCol"
      >
        <a-row>
          <a-col span="6">
            <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
          </a-col>
          <a-col span="10">
            <a-button @click="handleGoBack">返回</a-button>
          </a-col>
          <a-col span="8"></a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
import { detailGoods, editGoods, listCatAndBrand } from '@/api/wx_goods'
import { MessageBox } from 'element-ui'
import pick from 'lodash.pick'
import { editChildCategory, editParentCategory, listParentCategory } from '@/api/wx_shopmall_manage'
import { oss_token_list } from '@/api/oss_token'

const fields = ['category_name', 'keywords', 'level', 'sort_order', 'icon_url', 'picture_url', 'desc', 'parent_name']
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  name: 'GoodsEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0,
      previewVisible: false,
      previewVisible2: false,
      actionUrl: '',
      fileList: [],
      fileList2: [],
      previewImage: '',
      previewImage2: '',
      mysort_order: '1',
      new_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      },
      new_multipart_params2: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      }
    }
  },
  beforeCreate () {
    oss_token_list().then(({ data }) => {
      console.log(data, '我是返回值')
      this.new_multipart_params.OSSAccessKeyId = data.accessid
      this.new_multipart_params.policy = data.policy
      this.new_multipart_params.signature = data.signature
      this.actionUrl = 'https://static.jnzbtz.com'
    })
    oss_token_list().then(({ data }) => {
      console.log(data, '我是返回值')
      this.new_multipart_params2.OSSAccessKeyId = data.accessid
      this.new_multipart_params2.policy = data.policy
      this.new_multipart_params2.signature = data.signature
      this.actionUrl = 'https://static.jnzbtz.com'
    })
  },
  mounted () {
    this.$nextTick(() => {
      // this.loadEditInfo(this.record)
      listParentCategory({ id: this.$route.params.id })
        .then(res => {
          res.data.entries.map((e, i, arr) => {
            e.level = 1
            e.children.map((ee, ii, arr2) => {
              ee.category_name = ee.name
              ee.key = ee.id
              // ee.icon_url = ee.iconUrl
              // ee.picture_url = ee.picUrl
              ee.level = 2
              ee.parent_name = e.category_name
              ee.sort_order = ee.sort_order === undefined ? 2 : ee.sort_order
            })
          })
          const list = res.data.entries
          if (list && list.length > 0) {
            this.loadEditInfo(list[0])
          }
        })
    })
  },
  computed: {
    headers () {
      return {
        'X-Litemall-Admin-Token': '1'
      }
    },
    attributesData () {
      var attributesData = []
      for (var i = 0; i < this.attributes.length; i++) {
        if (this.attributes[i].deleted) {
          continue
        }
        attributesData.push(this.attributes[i])
      }
      return attributesData
    }
  },
  created () {
    this.init()
  },
  methods: {
    loadEditInfo (data) {
      const { form } = this
      const formData = pick(data, fields)
      console.log('formData', formData)
      this.id = formData.id
      this.fileList = formData.icon_url
        ? [{
          url: formData.icon_url,
          uid: '1',
          name: 'xxx.png',
          status: 'done',
          response: 'Server Error 500'
        }]
        : []
      this.fileList2 = formData.picture_url
        ? [{
          url: formData.picture_url,
          uid: '1',
          name: 'xxx.png',
          status: 'done',
          response: 'Server Error 500'
        }]
        : []
      form.setFieldsValue(formData)

      // console.log(`将加载 ${this} 信息到表单`)
      // gateway_application_list({ id: data.id })
      //   .then(({ data }) => {
      //     const result = data.entries[0]
      //     const formData = pick(result, fields)
      //     console.log('formData', formData)
      //     this.id = formData.id
      //     form.setFieldsValue(formData)
      //   })
    },
    beforeUpload (file) {
      this.new_multipart_params.key = file.uid + '_' + file.name
      this.form.setFieldsValue({ icon_url: this.new_multipart_params.key })
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('你只能上传jpeg/png格式的图片')
      }
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$message.error('文件过大，请重新上传')
      }
      return isJpgOrPng && isLt2M
    },
    beforeUpload2 (file) {
      this.new_multipart_params2.key = file.uid + '_' + file.name
      this.form.setFieldsValue({ picture_url: this.new_multipart_params2.key })
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('你只能上传jpeg/png格式的图片')
      }
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$message.error('文件过大，请重新上传')
      }
      return isJpgOrPng && isLt2M
    },
    // 进口图片
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj, () => {})
      }
      console.log('我要预览', file)
      this.previewImage = file.url || file.preview || file.thumbUrl
      this.previewVisible = true
    },
    async handlePreview2 (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj, () => {})
      }
      console.log('我要预览', file)
      this.previewImage2 = file.url || file.preview || file.thumbUrl
      this.previewVisible2 = true
    },
    handleChange ({ fileList, file }) {
      this.fileList = fileList
      console.log('我是图片', file)
      if (file.status === 'removed') {
        this.form.setFieldsValue({ icon_url: '' })
        this.$message.error('请重新上传')
      }
      if (file.status === 'done') {
        this.form.setFieldsValue({ icon_url: this.actionUrl + '/' + this.new_multipart_params.key })
      }
    },
    handleChange2 ({ fileList, file }) {
      this.fileList2 = fileList
      console.log('我是图片', file)
      if (file.status === 'removed') {
        this.form.setFieldsValue({ picture_url: '' })
        this.$message.error('请重新上传')
      }
      if (file.status === 'done') {
        this.form.setFieldsValue({ picture_url: this.actionUrl + '/' + this.new_multipart_params2.key })
      }
    },
    init: function () {
      if (this.$route.query.id == null) {
        return
      }

      const goodsId = this.$route.query.id
      detailGoods(goodsId).then(response => {
        this.goods = response.data.data.goods
        if (this.goods.brandId === 0) {
          this.goods.brandId = null
        }
        if (this.goods.keywords === '') {
          this.goods.keywords = null
        }
        this.specifications = response.data.data.specifications
        this.products = response.data.data.products
        this.attributes = response.data.data.attributes
        this.categoryIds = response.data.data.categoryIds

        this.galleryFileList = []
        for (var i = 0; i < this.goods.gallery.length; i++) {
          this.galleryFileList.push({
            url: this.goods.gallery[i]
          })
        }
        const keywords = response.data.data.goods.keywords
        if (keywords !== null) {
          this.keywords = keywords.split(',')
        }
      })

      listCatAndBrand().then(response => {
        this.categoryList = response.data.data.categoryList
        this.brandList = response.data.data.brandList
      })
    },
    handleCategoryChange (value) {
      this.goods.categoryId = value[value.length - 1]
    },
    handleCancel: function () {
      // this.$store.dispatch('tagsView/delView', this.$route)
      // this.$router.push({ path: '/goods/list' })
      this.previewVisible = false
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        console.log('我要提交接口', values)
        if (!err) {
          // eslint-disable-next-line no-console
          console.log('Received values of form: ', values)
        }
        if (this.record.level === 1) {
          editParentCategory(values, this.record.id).then((res) => {
              this.handleGoBack()
              console.log(res, '修改成功')
            })
        } else {
          values.name = values.category_name
          values.iconUrl = values.icon_url
          values.picUrl = values.picture_url
          values.category_name = undefined
          editChildCategory(values, this.record.id).then((res) => {
            this.handleGoBack()
            console.log(res, '修改成功')
          })
        }
        // gateway_application_update(values, this.id)
        //   .then((res) => {
        //     this.handleGoBack()
        //     console.log(res, '修改成功')
        //   })
      })
    },
    handleEdit: function () {
      const finalGoods = {
        goods: this.goods,
        specifications: this.specifications,
        products: this.products,
        attributes: this.attributes
      }
      editGoods(finalGoods)
        .then(response => {
          this.$notify.success({
            title: '成功',
            message: '编辑成功'
          })
          this.$store.dispatch('tagsView/delView', this.$route)
          this.$router.push({ path: '/goods/list' })
        })
        .catch(response => {
          MessageBox.alert('业务错误：' + response.data.errmsg, '警告', {
            confirmButtonText: '确定',
            type: 'error'
          })
        })
    },
    handleClose (tag) {
      this.keywords.splice(this.keywords.indexOf(tag), 1)
      this.goods.keywords = this.keywords.toString()
    },
    showInput () {
      this.newKeywordVisible = true
      this.$nextTick(_ => {
        this.$refs.newKeywordInput.$refs.input.focus()
      })
    },
    handleInputConfirm () {
      const newKeyword = this.newKeyword
      if (newKeyword) {
        this.keywords.push(newKeyword)
        this.goods.keywords = this.keywords.toString()
      }
      this.newKeywordVisible = false
      this.newKeyword = ''
    },
    handleGoBack () {
      // this.$emit('onGoBack')
      this.$router.go(-1)
    },
    uploadOverrun: function () {
      this.$message({
        type: 'error',
        message: '上传文件个数超出限制!最多上传5张图片!'
      })
    },
    handleGalleryUrl (response, file, fileList) {
      if (response.errno === 0) {
        this.goods.gallery.push(response.data.url)
      }
    },
    handleRemove: function (file, fileList) {
      for (var i = 0; i < this.goods.gallery.length; i++) {
        // 这里存在两种情况
        // 1. 如果所删除图片是刚刚上传的图片，那么图片地址是file.response.data.url
        //    此时的file.url虽然存在，但是是本机地址，而不是远程地址。
        // 2. 如果所删除图片是后台返回的已有图片，那么图片地址是file.url
        var url
        if (file.response === undefined) {
          url = file.url
        } else {
          url = file.response.data.url
        }

        if (this.goods.gallery[i] === url) {
          this.goods.gallery.splice(i, 1)
        }
      }
    },
    specChanged: function (label) {
      if (label === false) {
        this.specifications = [
          { specification: '规格', value: '标准', picUrl: '' }
        ]
        this.products = [
          { id: 0, specifications: ['标准'], price: 0.0, number: 0, url: '' }
        ]
      } else {
        this.specifications = []
        this.products = []
      }
    },
    uploadSpecPicUrl: function (response) {
      this.specForm.picUrl = response.data.url
    },
    handleSpecificationShow (row) {
      this.specForm = Object.assign({}, row)
      this.specVisiable = true
    },
    handleSpecificationEdit () {
      this.specForm.updateTime = ''
      for (var i = 0; i < this.specifications.length; i++) {
        const v = this.specifications[i]
        if (v.id === this.specForm.id) {
          this.specifications.splice(i, 1, this.specForm)
          break
        }
      }
      this.specVisiable = false
    },
    handleProductShow (row) {
      this.productForm = Object.assign({}, row)
      this.productVisiable = true
    },
    uploadProductUrl: function (response) {
      this.productForm.url = response.data.url
    },
    handleProductEdit () {
      this.productForm.updateTime = ''
      for (var i = 0; i < this.products.length; i++) {
        const v = this.products[i]
        if (v.id === this.productForm.id) {
          this.products.splice(i, 1, this.productForm)
          break
        }
      }
      this.productVisiable = false
    },
    handleAttributeShow (row) {
      if (row.id) {
        this.attributeForm = Object.assign({}, row)
        this.attributeAdd = false
      } else {
        this.attributeForm = {}
        this.attributeAdd = true
      }
      this.attributeVisiable = true
    },
    handleAttributeAdd () {
      this.attributes.unshift(this.attributeForm)
      this.attributeVisiable = false
    },
    handleAttributeEdit () {
      // 这是一个trick，设置updateTime的值为空，告诉后端这个记录已编辑需要更新。
      this.attributeForm.updateTime = ''
      for (var i = 0; i < this.attributes.length; i++) {
        const v = this.attributes[i]
        if (v.id === this.attributeForm.id) {
          this.attributes.splice(i, 1, this.attributeForm)
          break
        }
      }
      this.attributeVisiable = false
    },
    handleAttributeDelete (row) {
      row.deleted = true
    }
  }
}
</script>
